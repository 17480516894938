import React, { useState, useEffect } from 'react';
import { makeStyles, styled } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import WorkflowButton from '../WorkflowButton';
import { TextField } from '@mui/material';
import usePagination from '@mui/material/usePagination';
import Loading from '../Loading';

import STCLogo from '../../img/stc.png';

const useStyles = makeStyles({
    function_bar: {
        background: "#E6E6E6",
        display: "flex",
        alignItems: "center",
        height: "56px"
    },
    function_text: {
        color: "#323232",
        font: "normal normal normal 22px Nimbus Sans",
        padding: "0 16px"
    },
    workflows: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "flex-start",
        margin: "auto",
        maxWidth: "864px",
        height: "358px"
    },
    paging: {
        position: "fixed",
        bottom: "32px",
        left: "50%",
        width: "auto",
        transform: "translateX(-50%)"
    },
    page_dot: {
        height: "12px",
        width: "12px",
        background: "#B4B4B4 0% 0% no-repeat padding-box",
        border: "1px solid #B4B4B4",
        opacity: "1",
        borderRadius: "50%",
        margin: "10px",
    },
    empty_workflow_container: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        margin: "10%"
    },
    empty_workflow_text_container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    empty_workflow_header: {
        color: "#1b89b0",
        fontSize: "1em",
    },
    empty_workflow_text: {
        margin: "8px 0",
        textAlign: "center"
    }
})

const Workflows = (props) => {
    const tenant = props.tenant;
    const classes = useStyles();
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [wfs, setWfs] = useState(undefined);
    const workflows = props.workflows;
    const [search, setSearch] = useState('');
    const [_isLoading, setIsLoading] = useState(true);
    // For detecting swiping
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const minSwipeDistance = 50;

    useEffect(() => {
        // TODO Cache search filters to improve performance on MFPs
        if (workflows !== undefined) {
            setIsLoading(true);
            let tempWorkflows = [...workflows].filter(workflow => {
                if (!search) {
                    return true;
                }
                return workflow.name.toLowerCase().includes(search.toLowerCase());
            });
            // Add placeholders used to make it look nice
            // TODO Redo CSS so this isn't required
            let boxesToAdd = 0;
            if (tempWorkflows.length % 4 > 0) {
                boxesToAdd = 4 - (tempWorkflows.length % 4);
            }
            for (let i = 0; i < boxesToAdd; i++) {
                const placeholder = {
                    id: `PLACEHOLDER${i}`,
                    name: `PLACEHOLDER${i}`,
                    placeholder: true
                }
                tempWorkflows.push(placeholder);
            }
            // Chop into pages
            const pageCount = Math.ceil(tempWorkflows.length / 8);
            setPages(pageCount);
            if (pageCount > 1) {
                tempWorkflows = tempWorkflows.slice(page * 8, Math.min((page * 8) + 8, tempWorkflows.length));
            }
            setWfs(tempWorkflows);
            setIsLoading(false);
        }
    }, [page, pages, search, workflows, props.workflows]);

    // Swipe detection functions
    const onTouchStart = (event) => {
        setTouchEnd(null);
        setTouchStart(event.targetTouches[0].clientX);
    }

    const onTouchMove = (event) => {
        setTouchEnd(event.targetTouches[0].clientX);
    }

    const onTouchEnd = (_event) => {
        if (!touchStart || !touchEnd) {
            return;
        }
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        // Change pages on swipe
        // These numbers are adjusted because page is zero indexed and handlePage
        // expects it to be off by 1
        if (isLeftSwipe) {
            handlePage(null, page+2);
        } else if(isRightSwipe) {
            handlePage(null, page);
        }
    }

    const handlePage = (_, value) => {
        if (value-1 >= 0 && value-1 < pages) {
            setPage(value-1);
        }
    }

    const Paginate = () => {
        const List = styled('ul')({
            listStyle: "none",
            padding: 0,
            margin: 0,
            display: "flex",
        });
        const { items } = usePagination({
            count: pages,
            onChange: handlePage,
            page: page+1
        });

        return (
            <nav>
                <List>
                    {items.map(({ _, type, selected, ...item }, index) => {
                        let children = null;
                        if (type === 'page') {
                            children = (
                                <button
                                    className={classes.page_dot}
                                    type="button"
                                    style={{
                                        background: selected ? '#007DA8 0% 0% no-repeat padding-box' : '#B4B4B4 0% 0% no-repeat padding-box',
                                    }}
                                    {...item}
                                >
                                </button>
                            );
                        }

                        return <li key={index}>{children}</li>
                    })}
                </List>
            </nav>
        );
    }

    const handleSearchText = (event) => {
        setSearch(event.target.value);
        setPage(0);
        setTimeout(() => {
            document.querySelector('#search-input').focus();
        }, 0);
    }

    const toolbarRender = () => {
        return (
            <div className={classes.function_bar}>
                <div className={classes.function_text}>{t('Select Workflow')}</div>
                <TextField
                    id="search-input"
                    placeholder={t("Search")}
                    variant="outlined"
                    size="small"
                    value={search}
                    onChange={handleSearchText}
                    sx={{
                        background: '#FFFFFF',
                        border: '1px solid #707070',
                        marginLeft: 'auto',
                        marginRight: '16px',
                        width: '293px',
                        '& .MuiInputBase-root': {
                            borderRadius: '0',
                            border: 'none'
                        }
                    }}
                />
            </div>
        );
    }

    const listRender = () => {
        return (
        <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            {toolbarRender()}
            <div className={classes.workflows}>
                {wfs.map(workflow => {
                    return <WorkflowButton key={workflow.id} onClick={() => props.startWfx(workflow.id)} workflow={workflow} tenant={tenant} />
                })}
            </div>
            {pages > 1 ? (
                <div className={classes.paging}>
                    <Paginate />
                </div>
            ) : ""}
        </div>
        )
    }

    const emptyRender = () => {
        // TODO See #118
        if (search) {
            return toolbarRender();
        }
        return (
            <div className={classes.empty_workflow_container}>
                <div>
                    <img src={STCLogo} alt="Dispatcher ScanTrip Cloud" />
                </div>
                <div className={classes.empty_workflow_text_container}>
                    <h2 className={classes.empty_workflow_header}>{t('No workflows found')}</h2>
                    <div className={classes.empty_workflow_text}>
                        {t('There are currently no workflows available.')}
                    </div>
                    <div className={classes.empty_workflow_text}>
                        {t('A workflow needs to be successfully created and running for it to be listed here.')}
                        {t('For more information, please refer to the documentation and sample workflows.')}
                    </div>
                </div>
            </div>
        );
    }

    return (
        wfs?.length !== undefined ? (
            wfs.length > 0 ? listRender() : emptyRender()
        ) : (
            <Loading />
        )
    )
}

export default Workflows
