import { useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';
import { lookupTenantFromIssuer } from '../../services/api';
import jwtDecode from 'jwt-decode';

import Header from '../Header';
import Loading from '../Loading';

const FromDipa = () => {
    const location = useLocation();
    const history = useHistory();
    const [_sqta, setSqta] = useLocalStorage('dipa-sqta', '');
    const [_noDipa, setNoDipa] = useLocalStorage(false);

    useEffect(() => {
        async function handleToken() {
            const params = new URLSearchParams(location.search);
            const tokenSQTA = location.state?.sqta || params.get("sqta");
            const tokenCognito = location.state?.cognito || params.get("cognito");
            if (tokenSQTA && tokenSQTA.length > 0) {
                setSqta(tokenSQTA);
                setNoDipa(false);
            }
            if (tokenCognito && tokenCognito.length > 0) {
                try {
                    const decodedCognito = jwtDecode(tokenCognito);
                    const iss = decodedCognito.iss;
                    const issuer = iss.slice(iss.lastIndexOf('/')+1)
                    const tenant = await lookupTenantFromIssuer(issuer);
                    history.push('/', { user: decodedCognito.username, tenant, accessToken: tokenCognito, client: { ClientId: decodedCognito.client_id } })
                } catch (e) {
                    console.error(e);
                }
            }
            history.push('/');
        }
        handleToken();
    }, [history, location.search, location.state?.cognito, location.state?.sqta, setSqta, setNoDipa])

    return (
        <>
            <Header />
            <Loading />
        </>
    )
}

export default FromDipa