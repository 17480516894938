import StackTrace from 'stacktrace-js';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';

import Header from '../Header';
import Loading from '../Loading';
import TenantSelector from '../TenantSelector';
import UserLogin from '../UserLogin';
import Workflows from '../Workflows';
import RocketImg from '../../img/rocket.png';

import {
    getTenants,
    getUserPoolAll,
    getWorkflows,
    hasTokenExpired,
    startWorkflow,
    refreshToken as refreshT
} from '../../services/api';

const useStyles = makeStyles({
    controls: {
        display: 'inline-flex',
        justifyContent: 'end',
        width: '73%'
    },
    header: {
        background: 'black',
        color: 'white',
        height: 32,
        padding: 8
    },
    logo: {
        display: 'inline-flex'
    },
    resultContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '400px',
        '& *': {
            margin: '12px'
        }
    },
    resultText: {
        maxWidth: '600px',
        textAlign: 'center'
    },
    title: {
        fontSize: '20px',
        margin: 8
    },
    toolbar: {
        alignContent: 'space-around',
        background: 'lightgray',
        display: 'flex',
        height: '48px',
        justifyContent: 'flex-end'
    },
    page: {
        position: 'relative',
        top: 65
    },
    card: {
        width: '400px',
        maxHeight: '350px',
        position: 'absolute',
        top: '15%',
        left: '25%',
        zIndex: '5'
    },
    cardBackground: {
        width: '100%',
        height: '100px',
        position: 'absolute',
        zIndex: '3'
    }
});

const MFP = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const { t } = useTranslation();
    const [wfx, setWFX] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [tenants, setTenants] = useState(undefined);
    const [noDipa, setNoDipa] = useLocalStorage("noDipa", false);
    const [user, setUser] = useLocalStorage('user', '');
    const [tenant, setTenant] = useLocalStorage('tenant', undefined);
    const [tenantSelected, setTenantSelected] = useState(false);
    const [accessToken, setAccessToken] = useLocalStorage('accessToken', undefined);
    const [_idToken, setIdToken] = useLocalStorage('idToken', undefined);
    const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', undefined);
    const [client, setClient] = useLocalStorage('client', undefined);
    const [errorText, setErrorText] = useState(undefined);
    const [errorFunction, setErrorFunction] = useState(undefined);

    const showError = (text, fn) => {
        setErrorText(text);
        setErrorFunction(fn);
    }

    const checkAccessToken = async () => {
        try {
            if (!accessToken || !tenant) {
                console.log('checkAccessToken: called and accessToken/tenant not set!', accessToken, tenant);
                return null;
            }

            if (hasTokenExpired(accessToken, 1800)) {
                console.log('Refreshing token');

                try {
                    const tokenResp = await refreshT(tenant.slug, refreshToken, client.ClientId, client.ClientSecret, tenant.region);
                    setIdToken(tokenResp.id_token);
                    setAccessToken(tokenResp.access_token);
                    return tokenResp.access_token;
                } catch (e) {
                    return null;
                }
            }

            return accessToken;
        } catch (e) {
            console.error(e);
            alert('Error refreshing token');
            alert(e);
            const callback = (stackframes) => {
                const stringifiedStack = stackframes.map(sf => {
                    return sf.toString();
                }).join('\n');
                alert(stringifiedStack);
            }
            const errback = (_) => {
            }
            StackTrace.fromError(e).then(callback).catch(errback);
        }
    }

    const start = async (id) => {
        // Make request to start workflow
        setIsLoading(true);
        try {
            const token = await checkAccessToken();
            if (!token) {
                setIsLoading(false);
                showError(t('Your session has expired. Please log in again to continue.'), () => logout);
                return;
            }
            const response = await startWorkflow(id, tenant, token);
            if (response['$metadata'].httpStatusCode !== 200) {
                // TODO Error handle this
            }
            history.push('/processing', { arn: response.executionArn, token: response.token, url: response.url })
        } catch (e) {
            console.error(e);
            alert(e);
            const callback = (stackframes) => {
                const stringifiedStack = stackframes.map(sf => {
                    return sf.toString();
                }).join('\n');
                alert(stringifiedStack);
                setIsLoading(false);
            }
            const errback = (_) => {
                setIsLoading(false);
            }
            StackTrace.fromError(e).then(callback).catch(errback);
        }
    }

    const clearLocationState = () => {
        history.replace({ ...history.location, state: undefined });
    }
    const cbClearLocationState = useCallback(clearLocationState, [history]);

    const workflowResult = location.state?.success;
    const workflowList = () => {
        return (
            isLoading ? (
                <Loading />
            ) : (
            <>
                <Workflows tenant={tenant} workflows={wfx} startWfx={start} />
            </>
            )
        )
    }
    const result = () => {
        return (
            workflowResult ? (
                <div className={classes.resultContainer}>
                    <img src={RocketImg} alt="Success" />
                    <div className={classes.resultText}>{t('Your document has been scanned, processed, and sent to the selected destination.')}</div>
                    <Button variant="contained" onClick={() => clearLocationState()}>{t('Continue')}</Button>
                </div>
            ) : (
                <div className={classes.resultContainer}>
                    <div className={classes.resultText}>{t('An error has occurred while processing your document. Please consult the workflow logs to determine the location and nature of the error.')}</div>
                    <Button variant="contained" onClick={() => clearLocationState()}>Continue</Button>
                </div>
            )
        );
    }

    const selectTenant = async (t) => {
        if (!t) {
            return
        }
        setIsLoading(true);
        const resp = await getUserPoolAll(t.slug, t.region);
        t.userPoolId = resp.poolId;
        t.clientId = resp.clientId;
        t.userPool = resp.userPool;
        t.client = resp.client;
        setTenantSelected(true);
        setTenant(t);
    }

    const logout = async () => {
        cbClearLocationState();
        history.push("/logout");
    }

    const cbLogout = useCallback(logout, [cbClearLocationState, history]);

    const redirect = () => {
        setIsLoading(true);
        history.push('./dipa');
    }

    const pageSelect = () => {
        if (!tenant) {
            return <TenantSelector tenants={tenants} loading={isLoading} onClick={selectTenant} />
        } else if (!user && !location.state?.user) {
            if (tenantSelected) {
                return <UserLogin tenant={tenant} />
            } else {
                setTenant(undefined);
                setIsLoading(false);
            }
        } else {
            return workflowResult === undefined ? workflowList() : result()
        }
    }

    const errorDialog = () => {
        if (!errorText) {
            return <></>
        }
        return (
            <div>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography>Error</Typography>
                        <Typography>
                            {errorText}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button onClick={() => { errorFunction() } }>{t('OK')}</Button>
                    </CardActions>
                </Card>
                <div classname={classes.cardBackground}></div>
            </div>
        );
    }

    const cbSelectTenant = useCallback(selectTenant, [setTenant, setTenantSelected]);
    const cbCheckAccessToken = useCallback(checkAccessToken, [accessToken, client, tenant, refreshToken, setAccessToken, setIdToken]);
    useEffect(() => {
        const stateTenant = location.state?.tenant;
        const stateUser = location.state?.user;
        if(location.state?.noDipa ){
            setNoDipa(true);
        }
        const stateAccessToken = location.state?.accessToken;
        const stateIdToken = location.state?.idToken;
        const stateRefreshToken = location.state?.refreshToken;
        const stateClient = location.state?.client;
        if (stateTenant && stateUser) {
            setUser(stateUser);
            setTenant(stateTenant);
            setAccessToken(stateAccessToken);
            setIdToken(stateIdToken);
            setRefreshToken(stateRefreshToken);
            setClient(stateClient);
        }
        // This function exists to satisfy a React warning about useEffect functions being synchronous
        async function fetchTenants() {
            const tlist = await getTenants();
            setTenants(tlist);
            if (tlist.length === 1) {
                cbSelectTenant(tlist[0]);
            }
            setIsLoading(false);
        }

        if (tenants === undefined) {
            fetchTenants();
        }
    }, [
        cbSelectTenant,
        location,
        setAccessToken,
        setClient,
        setIdToken,
        setRefreshToken,
        setTenant,
        setUser,
        tenants,
        setNoDipa
        ]);

    useEffect(() => {
        async function fetchWorkflows() {
            const token = await cbCheckAccessToken();

            if (token) {
                const workflows = await getWorkflows(token);
                setWFX(workflows);
            } else {
                setWFX([]);
            }

            setIsLoading(false);
        }

        if (!accessToken || hasTokenExpired(accessToken)) {
            setUser(undefined);
            setAccessToken(undefined);
            setIdToken(undefined);
            setRefreshToken(undefined);
        } else if (tenant && !Array.isArray(wfx)) {
            fetchWorkflows();
        }
    }, [
        accessToken,
        cbCheckAccessToken,
        cbLogout,
        history,
        setAccessToken,
        setIdToken,
        setRefreshToken,
        setUser,
        tenant,
        wfx,
    ]);

    return (
        <>
            <Header user={user} tenant={tenant?.name} logout={logout} redirect={redirect} noDipa={noDipa}/>
            <div className={classes.page}>{pageSelect()}</div>
            <div>{errorDialog()}</div>
        </>
    )
}

export default MFP