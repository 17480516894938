import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import Header from '../Header';
import Loading from '../Loading';

const NoDipa = () => {
    const history = useHistory();

	useEffect(() => {
		history.push('/', {noDipa: true});
	},[history]);

    return (
        <>
            <Header />
            <Loading />
        </>
    )
}

export default NoDipa