import jwtDecode from 'jwt-decode';

const WFX_URL = (process.env.REACT_APP_WFX_URL || "http://localhost:3000");
const URLS = {
    DEV: 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev',
    STAGING: 'https://t09d4tj8oc.execute-api.us-east-1.amazonaws.com/beta',
    PROD: '???'
}

const _sortAndFilter = (input) => {
    const list = input.reduce((filtered, workflow) => {
        if (workflow?.status === "running") {
            if (workflow?.acl?.users?.length > 1 || workflow?.acl?.groups?.length > 0) {
                workflow.shared = true;
            }
            filtered.push(workflow);
        }
        return filtered;
    }, []);
    list.sort((a, b) => a.name.localeCompare(b.name));

    return list;
}

export const getWorkflows = async (token) => {
    if (typeof token !== 'string')
        throw new Error('token not a string')

    const MOCKDATA = [
        {
            "id": "1778458c-7e1c-4503-a59e-7248058da725",
            "name": "Workflow 1",
            "acl": {
                "users": [
                    {
                        "name": "Josh",
                        "role": "owner"
                    },
                    {
                        "name": "User1",
                        "role": "view"
                    }
                ]
            },
            "status": "running"
        },
        {
            "id": "Workflow 2",
            "name": "Workflow 2",
            "status": "running"
        },
        {
            "id": "Workflow 3",
            "name": "Workflow 3",
            "acl": {
                "users": [
                    {
                        "name": "Josh",
                        "role": "owner"
                    }
                ],
                "groups": [
                    {
                        "name": "Users",
                        "role": "view"
                    }
                ]
            },
            "status": "running",
        },
        {
            "id": "Workflow 4",
            "name": "Workflow 4",
            "status": "running"
        },
        {
            "id": "Workflow 5",
            "name": "Workflow 5",
            "status": "running"
        },
        {
            "id": "Workflow 6",
            "name": "Workflow 6",
            "acl": {
                "users": [
                    {
                        "name": "Josh",
                        "role": "owner"
                    },
                    {
                        "name": "User1",
                        "role": "view"
                    }
                ],
                "groups": [
                    {
                        "name": "Users",
                        "role": "view"
                    }
                ]
            },
            "status": "running"
        },
        {
            "id": "Workflow 7",
            "name": "Workflow 7",
            "status": "running"
        },
        {
            "id": "Workflow 8",
            "name": "Workflow 8",
            "status": "running"
        },
        {
            "id": "Workflow 9",
            "name": "Workflow 9",
            "status": "running"
        },
        {
            "id": "Long Title",
            "name": "This is a workflow with a really long title hahahahahahaha",
            "status": "running"
        },
        {
            "id": "1b501a9b9d2e493baaeee3c440c355ad",
            "name": "Scan to Google Drive",
            "status": "running"
        },
        {
            "id": "Draft Workflow",
            "name": "DRAFT",
            "status": "draft"
        }
    ]
    try {
        const resp = await fetch(`${WFX_URL}/wfx`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        const json = await resp.json();
        return _sortAndFilter(json);
    } catch (e) {
        if (window.location.host === 'localhost:3000') {
            return Promise.resolve(_sortAndFilter(MOCKDATA));
        }
        console.error(e);
        return Promise.reject(e);
    }
}

export const getWorkflowConfig = (_id) => {
    const MOCKDATA = 'http://localhost:3000'
    return MOCKDATA
}

export const startWorkflow = async (workflowId, tenant, token) => {
    const url = URLS[process.env.REACT_APP_STAGE || 'DEV'];
    const response = await fetch(`${url}/workflow/start`, {
        method: 'POST',
        body: JSON.stringify({
            workflowId,
            tenant
        }),
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    if (!response.ok) {
        // This exists to debug/remediate https://dev2.sec.kmbs.us/dispatcher-stratus/iws/dp-apps/-/issues/97
        // TODO Additional/different handling?
        alert(`${response.status}, ${response.statusText}`)
    }
    const json = await response.json();
    return json;
}

export const getWorkflowUI = async (arn) => {
    const url = URLS[process.env.REACT_APP_STAGE || 'DEV']
    const response = await fetch(`${url}/workflow/ui`, {
        method: 'POST',
        body: JSON.stringify({
            arn
        })
    })
    const json = await response.json();
    return json;
}

export const reportEnd = async (token, success, url=`${URLS[process.env.REACT_APP_STAGE || 'DEV']}/report-end`) => {
    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            token,
            success
        })
    });
    return response;
}

export const getWfxPreviewImgUrl = (tenantId, workflowId) => {
    return `${WFX_URL}/wfx/${workflowId}/preview/mfp.png?t=${tenantId}`;
}

const getMFPHash = async () => {
    try {
        const resp = await fetch(`http://127.0.0.1:8090/0A024AC9/getSerialHash.py`);
        const json = await resp.json();
        return json.hash;
    } catch (e) {
        console.error(e);
        return '';
    }
}

export const getTenants = async () => {
    const MOCKDATA = [
        {"id":"11111111-1111-1111-1111-111111111111","name":"SEC","slug":"sec","region":"us-east-1"}
    ]
    // return MOCKDATA
    try {
        const hash = await getMFPHash();
        if (hash) {
            const host = window.location.host;
            const parts = host.split('.');
            let baseHost = parts[parts.length - 2] + '.' + parts[parts.length - 1];
            if (window.location.host === 'localhost:3000') {
                baseHost = 'stratus.lol';
            }
            const resp = await fetch(`https://${baseHost}/api/device/tenants/${hash}`);
            const json = await resp.json();
            if (json.length === 0) {
                // TODO Remove this else once we have verified the above works
                const resp = await fetch(`${WFX_URL}/tenants`, {
                    method: 'GET',
                    headers: {
                        accept: 'application/json'
                    }
                });
                const json = await resp.json();
                return (json);
            }
            return (json);
        } else {
            // TODO Remove this else once we have verified the above works
            const resp = await fetch(`${WFX_URL}/tenants`, {
                method: 'GET',
                headers: {
                    accept: 'application/json'
                }
            });
            const json = await resp.json();
            return (json);
        }
    } catch (e) {
        if (window.location.host === 'mfp.stratus.lol') {
            console.error(e);
            // Used for debugging at the MFP
            return Promise.resolve(MOCKDATA);
        }
        if (window.location.host === 'localhost:3000') {
            return Promise.resolve(MOCKDATA);
        }
        console.error(e);
        return Promise.reject(e);
    }
}

export const getToken = async (domain, region, code, client_id, client_secret, redirect_uri, grant_type='authorization_code') => {
    const formData = {
        code,
        client_id,
        client_secret,
        grant_type,
        redirect_uri
    };
    const params = new URLSearchParams(formData);
    const body = params.toString();
    const tokenResp = await fetch(`https://${domain}.auth.${region}.amazoncognito.com/oauth2/token`, {
        method: 'post',
        body,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return await tokenResp.json();
}

export const revokeToken = async (domain, refresh_token, client_id, client_secret, region='us-east-1') => {
    const formData = {
        token: refresh_token,
        client_id
    }
    const params = new URLSearchParams(formData);
    const body = params.toString();

    return await fetch(`https://${domain}.auth.${region}.amazoncognito.com/oauth2/revoke`, {
        method: 'post',
        body,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${btoa(`${client_id}:${client_secret}`)}`
        }
    }).catch(err => {
        console.error('revokeToken:', err);
    });
}

export const refreshToken = async (domain, refresh_token, client_id, client_secret, region='us-east-1') => {
    const formData = {
        client_id,
        refresh_token,
        grant_type: 'refresh_token'
    }
    const params = new URLSearchParams(formData);
    const body = params.toString();
    const tokenResp = await fetch(`https://${domain}.auth.${region}.amazoncognito.com/oauth2/token`, {
        method: 'post',
        body,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${btoa(`${client_id}:${client_secret}`)}`
        }
    });
    return await tokenResp.json();
}

export const getUserPoolClient = async (poolId, clientId, region) => {
    const url = URLS[process.env.REACT_APP_STAGE || 'DEV'];
    const response = await fetch(`${url}/userpool/client`, {
        method: 'post',
        body: JSON.stringify({
            poolId,
            clientId,
            region
        })
    });
    return await response.json();
}
export const getUserPoolId = async (_domain, _region) => {

}

export const getUserPoolClientId = async (_poolId, _region) => {

}

export const getUserPool = async (_poolId, _region) => {

}

export const getUserPoolAll = async (slug, region) => {
    const url = URLS[process.env.REACT_APP_STAGE || 'DEV'];
    const response = await fetch(`${url}/userpool/all`, {
        method: 'post',
        body: JSON.stringify({
            slug,
            region
        })
    });
    return await response.json();
}

export const lookupTenantFromIssuer = async(issuer) => {
    const url = WFX_URL;
    const response = await fetch(`${url}/tenants/${issuer}`);
    return await response.json();
}

export const getTokenExpiration = (token) => {
    try {
        const now = Math.floor(Date.now() / 1000);
        const decodedToken = jwtDecode(token);

        if (!decodedToken)
            return -1;

        const ret = decodedToken.exp - now;
        return ret;
    } catch (e) {
        console.error(e);
    }

    return -1;
};

export const hasTokenExpired = (token, zero) => {
    zero = typeof zero !== "undefined" ? zero : 0;

    try {
        const exp = getTokenExpiration(token);
        return exp <= zero;
    } catch (e) {
        console.error(e);
    }
};