import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';

import STCLogo from '../../img/logo_scantrip_white.svg';
import ParagonLogo from '../../img/logo_paragon_white.png';
import Logout from '../../img/logout.svg';

const useStyles = makeStyles({
    controls: {
        display: 'inline-flex',
        justifyContent: 'flex-end',
        width: '79%',
        "& *": {
            margin: "0 5px !important"
        }
    },
    header: {
        background: '#323232',
        display: 'flex',
        color: 'white',
        height: 50,
        padding: 8,
        width: '100%',
        position: 'fixed',
        top: 0,
        zIndex: 1
    },
    logo: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    logo_img: {
        height: '37px'
    },
    logout_text: {
        fontSize: 'medium',
        color: 'white'
    },
    text: {
        fontSize: "12px",
        textOverflow: "ellipsis",
        maxWidth: "300px",
        whiteSpace: "nowrap",
        overflow: "hidden"
    },
    text_holder: {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
        justifyContent: "space-evenly"
    }
});

const Header = (props) => {
    const classes = useStyles();
    const _history = useHistory();
    const visibility = props.user ? 'visible' : 'hidden'; 
    const hideParagonButton = props.noDipa ?? false;

    return (
        <div className={classes.header}>
            <div className={classes.logo}>
                <img src={STCLogo} alt="Dispatcher ScanTrip Cloud" />
            </div>
            <div className={classes.controls}>
                <div className={classes.text_holder}>
                    <div className={classes.text}>{props.user}</div>
                    <div className={classes.text} hidden={props.user ? undefined : 'hidden'}>{props.tenant}</div>
                </div>
               {!hideParagonButton &&  <Button sx={{ maxWidth: "200px", maxHeight: "48px", background: "#434242", fontSize: "10px" }} onClick={() => props.redirect()}>
                    <img src={ParagonLogo} alt="Dispatcher Paragon" />
                </Button>}
                <IconButton size="large" sx={{ background: "#434242", borderRadius: 1.5, color: "lightgray", padding: "12px 6px", visibility }} onClick={() => props.logout()}>
                    <img className={classes.logo_img} src={Logout} alt="Logout" />
                </IconButton>
            </div>
        </div>
    );
}

export default Header;